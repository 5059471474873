/*********** COLOURS USED ************/

:root {
  --primary-dark: #121212;
  --primary-light: #fff;
  --primary-gradient: linear-gradient(
    90deg,
    #121212,
    #4a2fbd,
    #0080ff,
    #121212
  );
  --darker-gradient: linear-gradient(90deg, #000000, #2a1e98, #0065cc, #000000);
}

/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: var(--primary-dark) !important;
  color: var(--primary-light) !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/************ Navbar Css ************/

.navbar-toggler-icon {
  background-color: #000;
  border-radius: 4px;
  padding: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: #000 0.3s ease-in-out;
}
.navbar-toggler:hover .navbar-toggler-icon {
  background-color: #000;
}
.navbar.mobile-nav-open {
  background-color: #000;
}
.navbar.mobile-nav .navbar-nav {
  flex-direction: column;
  text-align: center;
}
.navbar.mobile-nav .navbar-nav .navbar-link {
  padding: 10px 0;
}
.navbar.mobile-nav .navbar-text {
  margin-top: 20px;
}
.navbar.mobile-nav button {
  padding: 14px 24px;
  font-size: 16px;
}
.navbar.mobile-nav button span {
  font-size: 16px;
}
.navbar.mobile-nav .social-icon {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.navbar.mobile-nav .social-icon a {
  margin: 0 10px;
}
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  background-color: var(--primary-dark);
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: var(--primary-dark);
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: var(--primary-light) !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: flex;
  align-items: center;
}
.social-icon a {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--primary-light);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: var(--primary-light);
  border: 1px solid var(--primary-light);
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: var(--primary-light);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
  border-radius: 50px;
}
.navbar-text button:hover {
  color: var(--primary-dark);
  border-radius: 10px;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--primary-light);
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid var(--primary-light);
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--primary-light);
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: var(--primary-light);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: var(--primary-light);
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 160px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: var(--darker-gradient);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  width: 100%;
  text-align: center;
  border-radius: 50px 0px 50px 0px;
}
.banner h1 {
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: var(--primary-light);
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  border-radius: 50%;
  margin-left: 50px;
  overflow: hidden;
  transform-origin: center;
  transform: scale(1);
  transition: transform 0.3s ease-out;
}
.banner img:hover {
  animation: enlargeImage 0.3s ease-in-out; /* Apply the hover animation */
  transform: scale(1.2); /* Enlarge the image smoothly on hover */
}

/*********** THE TEXT EVERY 3 SECONDS *************/
.txt-rotate {
  animation: resizeText 3s linear infinite; /* 2 seconds animation, infinite loop */
  display: inline-block;
  font-size: 40px;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************* FOR THE TAB IN SKILLS *********** */
.skill .nav-pills {
  width: 80%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  text-align: center;
}
.skill .nav-pills:hover {
  cursor: pointer;
}
.skill .nav-pills .nav-item {
  width: 50%;
  padding: 0;
  margin: 0;
}
.skill .nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 15px 0;
  color: var(--primary-light);
  font-size: 18px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  border: none;
  border-right: 1px solid white;
}
.skill .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: var(--primary-gradient);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.skill .nav-pills .nav-link.active::before {
  width: 100% !important;
}
.skill .nav-pills .nav-link.active {
  border: none;
  background-color: transparent;
  color: var(--primary-light);
}
.nav-link#skills-tabs-tab-first {
  color: red;
}
/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

/************* FOR THE TAB IN PROJECTS *********** */

.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills:hover {
  cursor: pointer;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: var(--primary-light);
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: var(--primary-gradient);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid var(--primary-gradient);
  background-color: var(--primary-gradient);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid var(--primary-gradient);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border: 1px solid var(--primary-gradient);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid var(--primary-gradient);
  border-radius: 0 55px 55px 0;
}

.proj-img {
  width: 100%;
  height: 300px;
  padding: 10px;
  display: block;
  margin: 0 auto;
}
.proj-imgbx {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 24px;
  border: 1px solid var(--primary-light);
}
.proj-imgbx::before {
  content: "";
  background: var(--primary-gradient);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: var(--primary-gradient);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: var(--primary-light);
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: var(--primary-dark);
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-light);
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: var(--primary-dark);
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: var(--primary-light);
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: var(--primary-light);
}
.contact form button::before {
  content: "";
  background: var(--primary-dark);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-color: linear-gradient(90deg, #ff0000, #2e1e8d, #0059b3, #000000);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
}
.footer img {
  width: 30%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.social-icon-footer {
  display: inline-block;
  margin-left: 14px;
}
.social-icon-footer a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon-footer a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: var(--primary-light);
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon-footer a:hover::before {
  transform: scale(1);
}
.social-icon-footer a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon-footer a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* KEYFRAMES */

@keyframes resizeText {
  0% {
    font-size: 20px; /* Start size */
  }
  50% {
    font-size: 30px; /* Middle size */
  }
  100% {
    font-size: 20px; /* End size (same as start) */
  }
}
@keyframes resizeTextForSmallDevice {
  0% {
    font-size: 10px; /* Start size */
  }
  50% {
    font-size: 20px; /* Middle size */
  }
  100% {
    font-size: 10px; /* End size (same as start) */
  }
}
@keyframes enlargeImage {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.2); /* Enlarged size on hover */
  }
}

/* MEDIA QUERY FOR RESPONSIVE */

/* For Small Phone */
@media screen and (max-width: 399px) {
  .banner {
    padding: 120px 0 100px 0;
  }
  .banner .tagline {
    font-size: 15px;
  }
  .banner h1 {
    font-size: 25px;
  }
  .banner p {
    color: #b8b8b8;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }
  .txt-rotate {
    animation: resizeTextForSmallDevice 3s linear infinite; /* 2 seconds animation, infinite loop */
    display: inline-block;
  }
  .banner img {
    display: block;
    height: auto;
    width: 80%;
    margin: 0 auto; /* Center the image horizontally */
    padding-top: 40px;
  }
  .skill .nav-pills {
    width: 100%;
  }
  .project .nav.nav-pills .nav-link {
    font-size: 14px;
  }
}
/* For all screen sizes within the specified range */
@media screen and (min-width: 400px) and (max-width: 1439px) {
  .banner img {
    display: block;
    height: auto;
    width: 80%;
    margin: 0 auto; /* Center the image horizontally */
    padding-top: 40px;
  }
}

/* For larger desktops */
@media screen and (min-width: 1440px) {
  .banner img {
    display: block;
    height: auto;
    width: 80%;
    margin: 0 auto; /* Center the image horizontally */
    padding-top: 40px;
  }
}

/* For NavBar Alone */
@media screen and (max-width: 767px) {
  .navbar-text button {
    height: 10px; /* Adjust the height as needed */
    width: auto;
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content */
  }
}
